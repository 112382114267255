import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              '\nBASE_URL = "/";\nIS_LOGGEDIN = "0";\nSITE_URL = BASE_URL;\nLANG_BASE_URL = "/";\n'
          }}
        />

        <script
          async
          type="text/javascript"
          src="/static/js/generated_js/958a58972bf272cd5ead1fe8b4a225e1.js?=1588071958"
        />
        <script async typ="text/javascript" src="/static/js/generated_js/jquery-migrate-1.4.1-min.js" />
        <div id="layout">
          <div className="head">
            <div className="inner">
              <a className="logo" href="/">
                <img src="/static/i/layout/New_Logo.png" alt="VISINE" />
              </a>
              <div className="menuHolder">
                <ul id="menu">
                  <li className="mainMenu">
                    <a href="/">Főoldal</a>
                  </li>
                  <li className="mainMenu">
                    <a href="/voros-szemre/">Vörös szemre</a>
                  </li>
                  <li className="mainMenu">
                    <a className="active" href="/termekeink/visine-classic/">
                      VISINE<sup>®</sup> Rapid
                    </a>
                  </li>
                  <li className="mainMenu">
                    <a href="/szemtorna/">Szemtorna</a>
                  </li>
                  <li className="mainMenu">
                    <a href="/kapcsolat/">Kapcsolat</a>
                  </li>
                </ul>
              </div>
              <div className="clear" />
            </div>
          </div>
          <div id="content" className="content">
            <div className="productPage" id="visineClassicPage">
              <div className="inner">
                <h1>
                  VISINE<sup>®</sup> Rapid
                </h1>
                <div className="product">
                  <img
                    className="productImage"
                    src="/static/i/products/Visine-Rapid-front-168x310.png"
                    alt="VISINE Rapid 0,5 mg/ml oldatos szemcsepp"
                  />
                  <div className="textHolder">
                    <div className="productName">
                      VISINE<sup>®</sup> Rapid <br />
                      0,5 mg/ml oldatos szemcsepp
                    </div>
                    <div className="desc">
                      30-60 másodperc alatt csökkenti a szem vörösségét, hatása akár 8
                      órán át tart.
                    </div>
                  </div>
                </div>
                <div className="mainText">
                  <div className="block">
                    <p>
                      A szem kisebb, nem fertőző irritációi (amit például füst, por, szél, klóros víz, fény vagy allergia okozhat) okozta szemvörösség átmeneti kezelésére alkalmazható felnőtteknél, serdülőknél és 2 éves vagy idősebb gyermekeknél. A 2 évesnél idősebb, de 6 évesnél fiatalabb gyermekeknél csak egészségügyi szakember tanácsát követően alkalmazható.
Hatása 30-60 másodpercen belül kialakul, és akár 8 órán át tart.
                    </p>
                  </div>
                  <div className="block">
                    <p>
                      Cseppentsen napi 2-3-szor 1-2 cseppet az érintett szem(ek)be..
                    </p>
                    <p>A Visine<sup>®</sup> Rapid 2 évesnél idősebb, de 6 évesnél fiatalabb gyermekek kezelésére csak egészségügyi szakember tanácsát követően javallott.</p>
                    <p>
                      Visine<sup>®</sup> Rapid 0,5 mg/ml oldatos szemcsepp 15 ml-es kiszerelésben kapható.
                    </p>
                    <p>
                      <a
                        href="https://ogyei.gov.hu/gyogyszeradatbazis&action=show_details&item=180073"
                        target="_blank"
                        className="pdf"
                      >
                        Itt elolvashatja a készítmény betegtájékoztatóját.
                      </a>
                    </p>
                    <p>
                      Használata csak a szem enyhe irritációja esetén ajánlott.
                    </p>
                  </div>
                  <div className="block">
                    <p>
                    Ha a tünetek 48 órán belül nem javulnak, a szemvörösség vagy az irritáció megmarad vagy súlyosbodik, vagy látásváltozást, szemfájdalmat tapasztal, a készítmény alkalmazását abba kell hagyni, és orvoshoz kell fordulni. A fertőzés, szembe került idegentest, vagy a szaruhártya kémiai sérülése okozta irritáció vagy szemvörösség szintén a kezelőorvos beavatkozását teszi szükségessé. Fejfájás, látásvesztés, látászavarok (például lebegő pontok vagy kettős látás), erős heveny szemvörösség vagy az egyik szem vörössége, vagy fény okozta fájdalom előfordulása esetén a készítmény alkalmazását abba kell hagyni és orvoshoz kell fordulni, mivel ez azt jelentheti, hogy súlyos szembetegsége van, amely ezzel a gyógyszerrel nem kezelhető.
                    </p>
                  </div>
                  <div className="block">
                    <p>
                      Ha kontaktlencséket visel, az alkalmazás előtt vegye ki őket, és az alkalmazást követő 15 percig ne tegye vissza őket.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
          <div className="footer">
            <div className="inner">
              <div className="productStatus">
                <div className="contentInner">
                  <div className="footerInner">
                    VISINE<sup>®</sup> Rapid 0,5 mg/ml oldatos szemcsepp. Vény
                    nélkül kapható, tetrizolin-hidroklorid hatóanyagú gyógyszer.
                  </div>
                </div>
              </div>
              <div className="footerMenu">
                <ul>
                  <li>
                    <a href="/jogi-nyilatkozat/">Jogi nyilatkozat</a>
                  </li>
                  <li>
                    <a href="/adatvedelmi-szabalyzat/">
                      Adatvédelmi szabályzat
                    </a>
                  </li>
                  <li>
                    <a href="/oldalterkep/">Oldaltérkép</a>
                  </li>
                  <li>
                    <a href="/cookie-szabalyzat/">Cookie szabályzat</a>
                  </li>
                  <li>
                    <a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie-beállítások</a>
                  </li>
                </ul>
              </div>
              <div className="johnson">
                <div className="contentInner">
                  <div className="footerInner">
                    <p className="bigger">©JNTL CONSUMER HEALTH (HUNGARY) KFT., 2023. 1112 Budapest, BudapestOne Center Balatoni út 2. a. ép. 1. em.</p>
                    <p>
                      Ezt az oldalt a JNTL Consumer Health (Hungary) Kft. tette közzé, és
                      felel annak tartalmáért.
                    </p>
                    <p>
                      Ez az oldal magyarországi felhasználók számára készült.
                    </p>
                    <p>Utoljára frissítve: 2023.02.01.</p>
                    <p>HU-VI-2200008</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="risks">
            <div className="contentInner">
              <div className="footerInner">
                A kockázatokról és a mellékhatásokról olvassa el a
                betegtájékoztatót, vagy kérdezze meg kezelőorvosát,
                gyógyszerészét!
              </div>
            </div>
          </div>
        </div>


      </div>
    );
  }
}

export default Page;
