/**
 * Copyright © 2019 Johnson & Johnson
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { Helmet } from "react-helmet";
import Page from "./Page";
import Gdpr from "src/components/Gdpr";

export default class IndexPage extends React.Component {
  render() {
    if (typeof window !== "undefined") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: "https://github.com/facebook/react/issues/10923"
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <Page />
        <Helmet>
          <html lang="hu" />
          <body />

          <script innerHTML='(window.digitalData = window.digitalData || [];
    digitalData.push({
       "event": "Page Loaded",
       "page": {
            "analyticsId":"UA-91649088-100",
            "brandName":"Visine",
            "country":"hu",
            "flattened":true,
            "hostname":"www.visine.hu",
            "language":"hu",
            "siteTier":"low",
         }
    }););' />
          <script innerHTML="(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','globalDataLayer','GTM-PJSTS9Z');" />
          <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />
          <meta name="author" content="::progressive::" />
          <meta name="publisher" content="::progressive::" />
          <meta
            name="description"
            content="VISINE® Rapid 0,5 mg/ml oldatos szemcsepp Percek alatt csökkenti a szem vörösségét, hatása akár 8 órán át tart."
          />
          <meta name="googlebot" content="index,follow" />
          <meta name="revisit-after" content="2 days" />
          <meta name="copyright" content />
          <meta name="distribution" content="Global" />
          <meta
            name="Keywords"
            content="Visine, Visine Classic, Visine szemcsepp, Visine Classic szemcsepp, vörös szem, szem irritáció, szem viszketés, szem égető érzés"
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1"
          />
          <link
            rel="shortcut icon"
            href="/static/i/favicon.ico"
            type="image/x-icon"
          />

          <title>VISINE® Rapid | VISINE®</title>
          <meta
            property="og:image"
            content="http://visine.hu/static/images/layout/facebook_share_fix.jpg"
          />
          <link
            href="/static/css/generated_css/db53bd38efbab4a839a2b41f5144f7a7.css?=1588071958"
            type="text/css"
            rel="stylesheet"
          />
        </Helmet>
        <Gdpr />
        ;
      </React.Fragment>
    );
  }
}
